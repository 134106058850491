import React from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text,
    Container,
    Grid,
    SimpleGrid,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Center,
    HStack,
    VStack,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';

import Navbar from './navbar';

const greenGradient = {
    // backgroundImage: 'linear-gradient(to right, green, white, green)',
    backgroundImage: 'linear-gradient(to right, green, blue, purple)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
};

import Github from "../images/github.png";
import { BsFileCode, BsFileEarmarkText } from 'react-icons/bs';
import Kickstarter from '../images/kickstarter.png';
import TitleWithColorBar from './titlewithcolorbar';

const topLinks = [
    {"icon": Github, "title": "GitHub", "url": "https://github.com/Bairminer/Z3-Public"}
];

import Hero from "../images/z3leo/hero.png";

import Jst from "../images/z3leo/jst.png";
import Tiny from "../images/sunrisegrb/tiny.png";
import Cover from "../images/z3leo/cover.png";
import Led from "../images/z3leo/led.png";
import Code from "../images/sunrisegrb/code.png";
import Usb from "../images/z3leo/usb.png";

const hardware = [
    {"icon": Jst, "description": "Standard size JST SH female connector allows for easy connection to sensors and components"},
    {"icon": Tiny, "description": "ATTiny85 microcontroller supports I2C and has 8KB of storage"},
    {"icon": Cover, "description": "Protective plastic cover prevents shorts and diffuses power LED glow"},
    {"icon": Led, "description": "Bright green power LED on Z3 Leo and Z3 Breadboard adapter"},
    {"icon": Code, "description": "Fully reprogrammable via Arduino IDE"},
    {"icon": Usb, "description": "Built in USB pads allow Z3 Leo to be directly inserted into a USB port, saving space and providing power"},
];

import Overview from "../images/z3leo/overview.png";
import Io from "../images/z3leo/io.png";
import Prog from "../images/z3leo/prog.png"
import Cable from "../images/z3leo/cable.png";

const meetHardware = [
    {"image": Overview, "description": "Z3 Leo - The core component! Contains the heart of the ecosystem, an Atmel AVR processor, the ATTiny85. Also features a bright green power indicator, built in USB pads, and a protective plastic cover. Connects to other components via an eight pin JST SH connector."},
    {"image": Io, "description": "Z3 Breadboard Adapter - connects to Z3 Leo with a built in JST cable (soldered or seperate), and breaks it out into standard 0.1 inch breadboard headers. Labeled with pinouts (not shown). Features an onboard reset switch, along with a bright green power indicator."},
    {"image": Prog, "description": "Z3 Programmer - An Arduino Pro Micro, flashed with ArduinoISP and with a JST 8 pin connector wired for Z3 Leo directly soldered on, designed for quickly programming Z3 Leo without a dedicated Arduino."},
    {"image": Cable, "description": "Z3 Cable - A JST SH male connector with wires attached, can be soldered to sensors, components, or the Z3 Breadboard Adapter. Included with every Z3 Leo."}
];

import Programming1 from "../images/z3leo/ports.png";
import Programming2 from "../images/z3leo/programming2.png";
import Programming3 from "../images/z3leo/programming3.png";

import Z3programmer2 from "../images/z3leo/z3programmer2.png";

import ConnectionOptions1 from "../images/z3leo/connectionoptions1.png";
import ConnectionOptions2 from "../images/z3leo/connectionoptions2.png";
import Ports from "../images/z3leo/ports.png";

import ProjectSample1 from "../images/z3leo/projectsample1.png";
import ProjectSample2 from "../images/z3leo/projectsample2.png";
import ProjectSample3 from "../images/z3leo/projectsample3.png";

const projectSamples = [
    {"image": ProjectSample1, "description": "Z3 Blink - a simple script that blinks an LED to test that things are fully working!"},
    {"image": ProjectSample2, "description": "Z3 RGB - based off our SunriseRGB program, controls addressable LED strips"},
    {"image": ProjectSample3, "description": "Z3 Temp - an OLED display showing temperatures via a TMP36 sensor"},
];

import OpenSource from "../images/z3leo/opensource.png";

import DesignProcess1 from "../images/z3leo/designprocess1.png";
import DesignProcess2 from "../images/z3leo/designprocess2.png";

const designProcess = [
    {"image": DesignProcess1, "caption": "V1 vs V2 designs", "description": (
        <Text>
            Originally, Z3 Leo was designed around the ATTiny10 instead of the ATTiny85, which we thought would help reduce the material cost, but during our testing we found that the limited memory and storage made it difficult to use in many projects. The original board was also 1.6 mm thick, which had some fitment issues with USB ports. Additionally, sharp corners made it hard to insert properly.
            <br/>
            <br/>
            In the second version of Z3 Leo, we switched from the ATTiny10 to the ATTiny85, bringing a much better featureset and improved specs. We also rounded off the corners of the PCB and switched the thickness to 0.8 mm to make the microcontroller fit better in  USB ports.
        </Text>
        )},
    {"image": DesignProcess2, "caption": "Different cover prototypes", "description": "Another concern we had was with the surface mount components on Z3 Leo short circuiting on the metal innards of the USB port. After some testing, we concluded that the ATTiny85 chip was tall enough to protect its leads from short circuits, but the smaller components needed some kind of protection. Initially, we tried conformal coating, however it became damaged after a few insertions into the USB port. We went back to the drawing board and designed a thin 3D printed cover for these components, which worked much better and didn’t make Z3 Leo any more difficult to plug in. While our engineering samples have no cover or a black plastic cover, the production versions will have a translucent plastic cover to diffuse the light from the power LED."},
];

import ProductOptions1 from "../images/z3leo/productoptions1.png";
import ProductOptions2 from "../images/z3leo/productoptions2.png";
import ProductOptions3 from "../images/z3leo/productoptions3.png";

const productOptions = [
    {"image": ProductOptions1, "title": "Z3 Leo: $8", "description": ["Includes one Z3 Leo microcontroller and Z3 Cable", "DIY kit (not assembled) for $6)"]},
    {"image": ProductOptions2, "title": "Z3 Leo + Adapter: $10", "description": ["Includes one Z3 Leo microcontroller, Z3 Cable, and Z3 Adapters", "DIY kit (not assembled) for $8), pre-soldered Z3 Cable to Z3 adapter for $12"]},
    {"image": ProductOptions3, "title": "Five Pack Z3 Leo + Adapter + Programmer: $50", "description": ["Includes five Z3 Leo microcontrollers, Z3 Cables, and Z3 Adapters, along with one Z3 Programmer", "DIY kit (not assembled) for $40), pre-soldered Z3 Cables to Z3 adapter for $55"]}
];

const mobileTextWidth = "100%";

export default function Z3Leo() {
    return (
        <>
            <Navbar />

            <Flex
                align="center"
                justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
                direction={{ base: 'column' }}
                wrap="no-wrap"
                minH="70vh"
                px={{base: 4, md: 8}}
                mb={16}
                mx="auto"
                maxW={{base: "600px", md: "1200px"}}
            >

                <Heading fontSize={{base: "60px", md: '80px' }} mb={16} sx={greenGradient}>Z3 Leo</Heading>

                <Stack
                    // direction={{base: "row", md: "row"}}
                    // columns={{base: 2, md: 4}}
                    spacing={{base: 8, md: 16}}
                    mb={8}
                >

                    {
                        topLinks.map((link, index) =>
                            <Stack
                                direction={{base: "column", md: "column"}}
                                alignItems="center"
                            >

                                <Link to={link.url} target="_blank" rel="noopener noreferrer">
                                    <Image src={link.icon} w="60px"/>
                                </Link>

                                <Text>{link.title}</Text>

                            </Stack>

                        )
                    }

                </Stack>


                <TitleWithColorBar text="About" textSize={36} color="#38761d"/>

                <Stack
                    direction={{base: "column", lg: "row"}}
                    spacing={{ base: 8, lg: 16 }}
                    alignItems="center"
                    alignContent="center"
                    maxW={{ base: '100%', lg: '80%' }} // Set max width based on the Text maxW
                    mx="auto" // Center the Stack horizontally
                    mb={16}
                >

                    <Image src={Hero} w={{base: "100%", lg: "100%"}} maxW={{md: "400px", lg: '400px' }} shadow="2xl" rounded="1rem"/>

                    <Text
                        w={{base: "100%", md: "75%", lg: '100%'}}
                        maxW={{ lg: "500px"}}
                    >
                        Z3 Leo is a tiny microcontroller, so small that it fits inside a USB A port! We designed Z3 Leo as something you can simply integrate into a DIY project, through its small size and low cost. Although not as powerful as an Arduino Uno, Z3 Leo’s ATTiny85 processor has the computational power for many smaller projects, and is also fully compatible with the Arduino IDE through any one of many ATTiny microcontroller libraries. Z3 Leo can be programmed with an Arduino serving as an Arduino ISP, or by using our Z3 Programmer. Z3 Leo’s JST SH port allows it to be connected to sensors, other electronic components, or our breadboard adapter.
                    </Text>

                </Stack>


                <TitleWithColorBar text="Hardware" textSize={36} color="#38761d"/>

                <SimpleGrid
                    spacing={8}
                    // templateColumns={['1fr', '1fr', '1fr', 'repeat(auto-fill, minmax(300px, 1fr))']}
                    columns={{base: 1, md: 2, lg: 2}}
                    justify="center"
                    px={{base: 2, md: 8 }}
                    pt={0}
                    mb={16}
                >

                    {
                        hardware.map((item, index) =>

                            <HStack key={index} spacing="16px">
                                <Image src={item.icon} w="100px"/>
                                <Text maxW="300px">{item.description}</Text>
                            </HStack>
                        )
                    }

                </SimpleGrid>

                <TitleWithColorBar text="Meet the Hardware!" textSize={36} color="#38761d"/>

                {/* TODO: Not centered on medium and small screens. Maybe turn into stack instead of grid */}

                {/*<SimpleGrid*/}
                {/*    spacing={8}*/}
                {/*    // templateColumns={['1fr', '1fr', '1fr', 'repeat(auto-fill, minmax(300px, 1fr))']}*/}
                {/*    columns={{base: 1, lg: 2}}*/}
                {/*    justify="center"*/}
                {/*    p={8}*/}
                {/*    pt={0}*/}
                {/*    alignItems="center"*/}
                {/*    alignContent="center"*/}
                {/*    alignSelf="center"*/}
                {/*    mb={16}*/}
                {/*    spacingX={0}*/}
                {/*    spacingY={8}*/}
                {/*    mx="auto"*/}
                {/*>*/}
                {/*    {*/}
                {/*        meetHardware.map((item, index) =>*/}
                {/*            <>*/}
                {/*                <Image src={item.image} w="300px" maxW="300px" shadow="2xl" rounded="1rem"/>*/}
                {/*                <Text maxW="400px">{item.description}</Text>*/}
                {/*            </>*/}
                {/*        )*/}
                {/*    }*/}

                {/*</SimpleGrid>*/}


                <VStack
                    spacing={{base: 16, lg: 8 }}
                    mb={16}
                >
                    {
                        meetHardware.map((item, index) =>
                            <Stack
                                direction={{base: "column", lg: "row"}}
                                spacing={{base: 4, lg: 20}}
                                alignItems="center"
                            >
                                <Image src={item.image} w={{ base: "100%", md: "300px" }} maxW={{ md: '300px' }} shadow="2xl" rounded="1rem"/>
                                <Text maxW={{base: "100%", md: "60%", lg: '450px' }}>{item.description}</Text>
                            </Stack>
                        )
                    }

                </VStack>


                <TitleWithColorBar text="Programming" textSize={36} color="#38761d"/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    Z3 Leo can be programmed through the Arduino IDE as an ATTiny85 using any one of numerous libraries. To program the microcontroller, simply connect it via the included JST cable to an Arduino Uno or Mega running as an ArduinoISP… or
                </Text>

                <Image src={Programming1} w={{base: "100%", md: "60%"}} maxW={{base: "unset", lg: '500px' }} shadow="2xl" mb={16}/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    … connect the Z3 Leo via the Z3 Breadboard Adapter to an Arduino Uno or Mega running as an ArduinoISP.
                </Text>

                <Stack
                    direction={{base: "column", lg: "row"}}
                    spacing={{base: 0, lg: 8 }}
                    alignItems="center"
                    alignContent="center"
                >
                    <Image src={Programming2} w={{base: "100%", md: "100%"}} maxW={{ md: '400px' }} shadow="2xl" mb={16}/>
                    <Image src={Programming3} w={{base: "100%", md: "100%"}} maxW={{ md: '400px' }} shadow="2xl" rounded="1rem" mb={16}/>
                </Stack>

                <TitleWithColorBar text="Z3 Programmer" textSize={36} color="#38761d"/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    Alternatively, we offer a pre-assembled Arduino Pro Micro, flashed with ArduinoISP and with a JST 8 pin connector wired for programming Z3 Leo directly soldered on. This custom programmer can be directly plugged into Z3 Leo, helping reduce complexity and speed up programming.
                </Text>

                <Stack
                    direction={{base: "column", lg: "row"}}
                    spacing={{base: 0, lg: 16 }}
                    alignItems="center"
                    alignContent="center"
                >
                    <Image src={Prog} w={{base: "100%", md: "100%"}} maxW={{ md: '400px' }} shadow="2xl" mb={16}/>
                    <Image src={Z3programmer2} w={{base: "100%", md: "100%"}} maxW={{md: "400px", lg: '300px' }} shadow="2xl" rounded="1rem" mb={16}/>
                </Stack>


                <TitleWithColorBar text="Connection Options" textSize={36} color="#38761d"/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    Z3 Leo can be directly plugged inside a USB A port, with only the JST SH female port sticking out. Alternatively, the microcontroller can be powered via the VCC and GND wires on the Z3 Cable. Please make sure to connect the correct polarity wires, and that the input voltage is 5 volts to prevent damage to the microcontroller. Note that Z3 Leo cannot be programmed over the USB connection.
                </Text>

                <Stack
                    direction={{base: "column", lg: "row"}}
                    spacing={{base: 0, lg: 8 }}
                    alignItems="center"
                    alignContent="center"
                    mb={16}
                >
                    <Stack
                        direction={{base: "column", md: "row"}}
                        spacing={{base: 8, md: 8 }}
                        alignItems="center"
                        alignContent="center"
                        mb={{base: 8, lg: 0}}
                    >
                        <Image src={ConnectionOptions1} w={{base: "100%", lg: "100%"}} maxW={{base: 'unset', md: "200px" }} shadow="2xl"  rounded="1rem"/>
                        <Image src={ConnectionOptions2} w={{base: "100%", lg: "100%"}} maxW={{base: 'unset', md: "200px"}} shadow="2xl"  rounded="1rem"/>


                    </Stack>

                    <Image src={Ports} w={{base: "100%", md: "100%"}} maxW={{base: 'unset', md: "400px" }} shadow="2xl"/>

                </Stack>


                <TitleWithColorBar text="Project Samples" textSize={36} color="#38761d"/>


                <SimpleGrid
                    columns={{base: 1, md: 1, lg: 3}}
                    spacingX={{base: 0, md: 8 }}
                    spacingY={{base: 8}}
                    alignItems="center"
                    alignContent="center"
                    mb={16}
                >
                    {
                        projectSamples.map((sample, index) =>
                            <VStack
                                alignItems="center"
                                alignContent="center"
                                maxW={{ md: '400px', lg: "unset" }}
                            >
                                <Image src={sample.image} w={{base: "100%", lg: "100%"}} maxW={{base: 'unset', lg: "300px" }} shadow="2xl"  rounded="1rem"/>
                                <Text w={{base: "100%"}} maxW={{ lg: '250px' }}>{sample.description}</Text>
                            </VStack>
                        )
                    }
                </SimpleGrid>


                <TitleWithColorBar text="Open Source" textSize={36} color="#38761d"/>

                <Stack
                    direction={{base: "column-reverse", md: "row"}}
                    alignItems="center"
                    alignContent="center"
                    spacing={8}
                    mb={16}
                >
                    <Text w={{base: "100%"}} maxW={{base: "unset", md: '300px' }}>
                        Our sample projects and schematics can be found on our Github repository, linked on our website.
                    </Text>
                    <Image src={OpenSource} w="100%" maxW={{base: "unset", md: '300px' }}/>
                </Stack>

                <TitleWithColorBar text="Design Process" textSize={36} color="#38761d"/>

                {
                    designProcess.map((step) =>
                        <Stack
                            direction={{base: "column", lg: "row"}}
                            spacing={12}
                            mb={16}
                            alignItems="center"
                            alignContent="center"
                            maxW="850px"
                        >
                            <VStack
                                // mb={{base: 8, lg: 0}}
                            >
                                <Image src={step.image} w={{base: "100%", md: "100%"}} maxW={{base: "600px", md: "400px", lg: '450px' }} maxH="500px" shadow="2xl" rounded="1rem"/>
                                <Text mt="-2px" fontSize="12px">{step.caption}</Text>
                            </VStack>

                            <Text
                                w={{base: "100%", md: "75%", lg: "80%"}}
                                // maxW={{lg: "500px"}}
                            >{step.description}</Text>

                        </Stack>
                    )
                }


                <TitleWithColorBar text="Product Options" textSize={36} color="#38761d"/>

                <VStack
                    spacing={8}
                >
                    {
                        productOptions.map((option, index) =>
                            <Box
                                direction={{base: 'column', md: "row" }}
                                // overflow='hidden'
                                variant='outline'
                                bg="#38761d"
                                w={{base: "100%", md: "100%", lg: "90%"}}
                                maxW={{base: "500px", md: '3000px' }}
                                minH="200px"
                                key={index}
                                rounded="1rem"
                                p={{base: 8, md: 6}}
                                shadow="2xl"

                                // rounded="2rem"
                                // // borderRadius="lg"  // Adjust the border radius as needed
                                // border="6px solid #f1c231"
                                // backgroundColor="transparent"
                                // p={4}  // Add padding as needed
                                //
                                // w={{base: '90%', md: "100%" }}
                                // maxW={{base: "500px", lg: "300px"}}
                                // alignItems="center"
                                // alignContent="center"
                                // key={index}

                            >
                                <Stack
                                    direction={{base: "column", md: "row"}}
                                    spacing={8}
                                >
                                    <Image
                                        // objectFit='cover'
                                        w={{base: '100%'}}
                                        maxW={{ base: "450px", md: '250px' }}
                                        src={option.image}
                                        alt='Caffe Latte'
                                        // style={{alignSelf: { base: 'center', md: "start"}}}
                                        alignSelf={{ base: 'center', md: "center"}}
                                        // mt={{base: 8, md: 0}}
                                        // ml={{md: 4}}
                                        shadow="2xl"
                                        rounded="1rem"
                                    />

                                    {/*<VStack>*/}
                                        <Box>
                                            <Heading size='lg' pr={{md: 4 }} mb={2}>{option.title}</Heading>

                                            {/*<Text py='2' pr={{md: 4 }}>*/}

                                            <UnorderedList>
                                                {
                                                    option.description.map((bullet) =>
                                                        <ListItem>{bullet}</ListItem>
                                                    )
                                                }

                                            </UnorderedList>


                                            {/*</Text>*/}
                                        </Box>

                                    {/*</VStack>*/}

                                </Stack>

                            </Box>
                        )
                    }
                </VStack>





                <Text mt="100px">© AnomalyWC 2023 - △ ☽ α</Text>

            </Flex>
        </>
    );
}