import React from 'react';
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    // theme,
    extendTheme, ColorModeScript, CSSReset,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import Main from './components/main';
import theme from './theme';

function App() {
    return (
        <ChakraProvider theme={theme}>
            {/*<ColorModeScript initialColorMode={theme.config.initialColorMode} />*/}

            {/*<CSSReset />*/}
            {/*<div*/}
            {/*    style={{*/}
            {/*        minHeight: '100vh',*/}
            {/*        background: "#000000",*/}
            {/*        display: 'flex',*/}
            {/*        flexDirection: 'column',*/}
            {/*    }}*/}
            {/*>*/}
                <Main />
            {/*</div>*/}

        </ChakraProvider>
    );
}

export default App;
