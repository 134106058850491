import React from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    Flex,
    Text,
    Button,
    Stack,
    useColorModeValue,
    Image,
    Menu,
    MenuButton,
    MenuList,
    IconButton, MenuItem,
} from '@chakra-ui/react';

import {
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'

import Logo from '../images/logowhite.png';
import { ColorModeSwitcher } from '../ColorModeSwitcher';

import { HamburgerIcon } from '@chakra-ui/icons';
import { BsHouse, BsHouseFill, BsGiftFill, BsFillInfoCircleFill } from 'react-icons/bs';

const navbarFontSize = 18;

const data = [
    {"name": "Home", "url": "/", "icon": BsHouseFill},
    {"name": "Products", "url": "/products", "icon": BsGiftFill},
    {"name": "About Us", "url": "/about", "icon": BsFillInfoCircleFill},
];

const CustomMenuItem = (props) => {
    const { children, isLast, to = '/', ...rest } = props;
    return (
        <Text
            mb={{ base: isLast ? 0 : 8, sm: 0 }}
            mr={{ base: 0, sm: isLast ? 0 : 8 }}
            display="block"
            fontWeight="bold"
            fontSize={navbarFontSize}
            transition="color 0.15s ease"
            _hover={{color: "rgba(125, 125, 125, 1)"}}
            {...rest}
        >
            <Link to={to}>{children}</Link>
        </Text>
    );
};

export default function Navbar(props) {
    const [show, setShow] = React.useState(false);
    const toggleMenu = () => setShow(!show);
    // const bgColor = useColorModeValue("white", "#1a202c");
    const bgColor = "#000000"
    const gradient = useColorModeValue('linear(to-r, #1b202b, #ffff)', 'linear(to-r, #1b202b, #000000)');

    return (

        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            minH="75px"
            mb={8}
            p={4}
            pt={4}
            pb={4}
            position="sticky" // Set position to sticky
            top="0" // Stick to the top
            zIndex="999" // Ensure it appears above other content
            style={{
                background: bgColor,
            }}
            shadow="dark-lg"
            // bgGradient={gradient}
            // bg={["primary.500"]}
            // color={["white", "white", "primary.700", "primary.700"]}
            {...props}
        >
            <Flex align="center">

                <Box display={{ base: 'block' }}>
                    <Link to="/">
                        <Image
                            src={Logo}
                            w="100px"
                            color={['white', 'white', 'primary.500', 'primary.500']}
                        />
                    </Link>
                </Box>

            </Flex>

            <Box display={{ base: "block", md: "None" }}>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon w={8} h={8}/>}
                        bg="transparent"
                        color="#ffffff"
                        // variant='outline'
                    />
                    <MenuList
                        // bg="#101010"
                        bg="black"
                    >

                        {
                            data.map((item, index) =>

                                <>
                                    <Link key={index} to={item.url} style={{ textDecoration: 'none' }}>
                                        <MenuItem
                                            // bg="#101010"
                                            bg="black"
                                            icon={<Box as={item.icon} w={6} h={6}/>}
                                            to={item.url}
                                            // ml={4}
                                            // p={2}
                                            fontFamily="oswald"
                                            fontSize={navbarFontSize}
                                            // fontWeight="semibold"
                                        >
                                            {item.name}
                                        </MenuItem>
                                    </Link>

                                    {index === data.length - 1 ? <></> : <MenuDivider />}
                                </>
                            )
                        }

                    </MenuList>
                </Menu>

            </Box>


            <Box
                display={{ base: show ? 'block' : 'none', md: 'block' }}
                flexBasis={{ base: '100%', md: 'auto' }}
            >
                <Flex
                    align={['center', 'center', 'center', 'center']}
                    justify={['center', 'space-between', 'flex-end', 'flex-end']}
                    direction={['column', 'row', 'row', 'row']}
                    pt={[4, 4, 0, 0]}
                >

                    {
                        data.map((item, index) =>
                            <CustomMenuItem to={item.url} fontFamily="oswald">{item.name}</CustomMenuItem>
                        )
                    }

                </Flex>
            </Box>
        </Flex>
    );
};