import React from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text, Container, Grid, SimpleGrid, Card, CardBody, CardHeader, CardFooter, Center, HStack, VStack,
} from '@chakra-ui/react';


export default function TitleWithColorBar({text, textSize, color, ...rest}) {
    return (
        <Box
            borderBottom={`8px solid ${color}`}
            ml={{base: "0%", md:'10%', lg: "10%", xl: "10%", }}
            // ml={{base: "0%"}}
            mb="24px"
            alignSelf={{base: "center", md: "start"}}
            {...rest}
        >
            <Heading fontSize={`${textSize}px`} fontWeight="bold">{text}</Heading>
        </Box>
    );
}