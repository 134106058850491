import React from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text,
    Container,
    Grid,
    SimpleGrid,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Center,
    HStack,
    VStack,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';

import Navbar from './navbar';
import TitleWithColorBar from './titlewithcolorbar';

import { BsFileEarmarkText } from 'react-icons/bs';
import { BsFileCode } from "react-icons/bs";
import Github from "../images/github.png";
import Kickstarter from "../images/kickstarter.png";

const topLinks = [
    {"icon": BsFileEarmarkText, "title": "User Manual", "url": "https://pdfhost.io/v/mTFXlmwaF_SunriseRGB_User_Manualpdf.pdf"},
    {"icon": BsFileCode, "title": "Default Program", "url": "https://pastebin.com/UDUJjvTG"},
    {"icon": Github, "title": "GitHub", "url": "https://github.com/Bairminer/SunriseRGB-Public"},
    {"icon": Kickstarter, "title": "Kickstarter", "url": "https://www.kickstarter.com/projects/anomalywc/sunrisergb-a-compact-reprogrammable-ws2812b-controller/"}
];

import Orange from "../images/sunrisegrb/orange.png";
import Yellow from "../images/sunrisegrb/yellow.png";
import Green from "../images/sunrisegrb/green.png";
import Blue from "../images/sunrisegrb/blue.png";
import Purple from "../images/sunrisegrb/purple.png";
import Magenta from "../images/sunrisegrb/magenta.png";

const colorPics = [Orange, Yellow, Green, Blue, Purple, Magenta];

import Wsrgb from "../images/sunrisegrb/wsrgb.png";
import Usbc from "../images/sunrisegrb/usbc.png";
import Code from "../images/sunrisegrb/code.png";
import Modes from "../images/sunrisegrb/rainbowcycle.png";
import Device from "../images/sunrisegrb/device.png";
import MaleToMale from "../images/sunrisegrb/header.png";

const features = [
    {"icon": Wsrgb, "description": "Supports up to 100 LEDS"},
    {"icon": Usbc, "description": "USB C makes it simple to power LED strips with up to 3 amps of power"},
    {"icon": Code, "description": "Fully reprogrammable via Arduino IDE"},
    {"icon": Modes, "description": "Preprogrammed with several cool lighting effects"},
    {"icon": Device, "description": "Sleek modern design blends in and protects the device from damage"},
    {"icon": MaleToMale, "description": "Easily connect off the shelf LED strips via the the onboard female 3 pin connector or with the included male to male adapter"},
];

import Buttons from "../images/sunrisegrb/buttonscropped.png";


import Blueleds from "../images/sunrisegrb/blueleds.png";
import Orangeleds from "../images/sunrisegrb/orangeleds.png";
import Flashingrainbow from "../images/sunrisegrb/flashingrainbow.png";
import Whiteleds from "../images/sunrisegrb/whiteleds.png";
import Redleds from "../images/sunrisegrb/redleds.png";
import Yellowleds from "../images/sunrisegrb/yellowleds.png";
import Greenleds from "../images/sunrisegrb/greenleds.png";
import Purpleleds from "../images/sunrisegrb/purpleleds.png";


const lightingModes = [
    {"icon": Modes, "description": "Rainbow Cycle"},
    {"icon": Blueleds, "description": "Blue"},
    {"icon": Wsrgb, "description": "Rainbow"},
    {"icon": Orangeleds, "description": "Orange"},
    {"icon": Flashingrainbow, "description": "Flashing Rainbow"},
    {"icon": Whiteleds, "description": "White"},
    {"icon": Redleds, "description": "Red"},
    {"icon": Yellowleds, "description": "Yellow"},
    {"icon": Greenleds, "description": "Green"},
    {"icon": Purpleleds, "description": "Purple"},
];

import Threethirtyone from "../images/sunrisegrb/331.png";
import Tiny from "../images/sunrisegrb/tiny.png";
import Header from "../images/sunrisegrb/header.png";
import Fivetwelve from "../images/sunrisegrb/512.png";
import Wssingle from "../images/sunrisegrb/wssingle.png";


const hardware = [
    {"icon": Threethirtyone, "description": "330 Ohm resistor protects LED strip from damage"},
    {"icon": Tiny, "description": "ATTiny85 can be easily reprogrammed via Arduino IDE"},
    {"icon": Header, "description": "Standard sized 0.1” headers easily connect to LED strips and wires"},
    {"icon": Fivetwelve, "description": "5.1 KOhm resistors ensure compliance with USB C spec"},
    {"icon": Wssingle, "description": "Integrated LED helps you test your code and makes the device blend in seamlessly"},
    {"icon": Usbc, "description": "USB C port allows for up to 3 amps of power for LED strips"}
];

import ConnectionOptions1 from "../images/sunrisegrb/DSC_0792e.JPG";
import ConnectionOptions2 from "../images/sunrisegrb/DSC_0795e.JPG";
import ConnectionOptions3 from "../images/sunrisegrb/DSC_0796e.JPG";

import CompactSize1 from "../images/sunrisegrb/DSC_0786e.JPG";

import OpenSource1 from "../images/sunrisegrb/DSC_0775e.JPG";

import MadeInUsa from "../images/sunrisegrb/IMG_2688.png";

import Tv from "../images/sunrisegrb/DSC_0798e.JPG";
import Desktop from "../images/sunrisegrb/DSC_0799e.JPG";
import Microwave from "../images/sunrisegrb/microwave.png";
import ChristmasTree from "../images/sunrisegrb/christmastree.png";
import Bike from "../images/sunrisegrb/bike.png";

const projectIdeas = [Tv, Desktop, ChristmasTree, Microwave, Bike];

import Board from "../images/sunrisegrb/board.png";

import EarlyPrototypes from "../images/sunrisegrb/earlyprototypes.png";
import Revision2 from "../images/sunrisegrb/revision2croppedrotated.png";

import OriginalCasing from "../images/sunrisegrb/originalcasing.png";
import NewCasing from "../images/sunrisegrb/newcasing.png";

import Option1 from "../images/sunrisegrb/DSC_0761.JPG";
import Option2 from "../images/sunrisegrb/DSC_0788.JPG";
import Option3 from "../images/sunrisegrb/roll.png";
import Option4 from "../images/sunrisegrb/DSC_0774e.JPG";
import { FaKickstarter } from 'react-icons/all';


const productOptions = [
    {"image": Option1, "title": "SunriseRGB", "description": "Includes one SunriseRGB, in black and silver"},
    {"image": Option2, "title": "SunriseRGB Early Bird", "description": "Includes one SunriseRGB, in black and gold"},
    {"image": Option3, "title": "SunriseRGB Starter Kit", "description": (
            <>
                Includes one SunriseRGB, USB power brick, 10 ft of WS2812B LEDs (100), and a USB C cable.
                <br />
                <br />
                <em><p style={{fontSize: 12}}>*Note: Final USB power brick and cord may vary from image.</p></em>
            </>
        )},
    {"image": Option4, "title": "SunriseRGB Five Pack", "description": "Includes five SunriseRGBs, in black and silver"}
];

const rainbowGradient = {
    backgroundImage: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
};

const mobileTextWidth = "100%";

export default function SunriseRGB() {
    return (
        <>
            <Navbar />

            <Flex
                align="center"
                justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
                direction={{ base: 'column' }}
                wrap="no-wrap"
                minH="70vh"
                px={{base: 4, md: 8}}
                mb={16}
                mx="auto"
                maxW={{base: "600px", md: "1200px"}}
                // alignItems="center"
                // alignContent="center"
            >

                {/*<Box alignSelf="start" borderBottom="8px solid #980000" ml="10%" mb="24px">*/}
                {/*    <Text fontSize="36px" fontWeight="bold">SunriseRGB</Text>*/}
                {/*</Box>*/}

                {/*<TitleWithColorBar text="SunriseRGB" textSize={36} color="#980000"/>*/}

                <Heading fontSize={{base: "60px", md: '80px' }} mb={16} sx={rainbowGradient}>SunriseRGB</Heading>

                {/*<TitleWithColorBar text="Links" textSize={36} color="#980000"/>*/}

                <SimpleGrid
                    // direction={{base: "row", md: "row"}}
                    columns={{base: 2, md: 4}}
                    spacing={{base: 8, md: 16}}
                    mb={8}
                >
                    {
                        topLinks.slice(0, 2).map((link, index) =>
                            <Stack
                                direction={{base: "column", md: "column"}}
                                alignItems="center"
                            >
                                <Link to={link.url} target="_blank" rel="noopener noreferrer">
                                    <Box as={link.icon} w="60px" h="60px" />
                                </Link>

                                <Text>{link.title}</Text>

                            </Stack>
                        )
                    }

                    {
                        topLinks.slice(2, topLinks.length).map((link, index) =>
                            <Stack
                                direction={{base: "column", md: "column"}}
                                alignItems="center"
                            >

                                <Link to={link.url} target="_blank" rel="noopener noreferrer">
                                    <Image src={link.icon} w="60px"/>
                                </Link>

                                <Text>{link.title}</Text>

                            </Stack>

                        )
                    }


                </SimpleGrid>


                <TitleWithColorBar text="About" textSize={36} color="#980000"/>

                <Text w={{base: mobileTextWidth, md: "75%"}}>
                    SunriseRGB is a simple way to control WS2812B/Neopixel addressable LED strips. We designed our
                    product to make it simpler to integrate these LED strips in your own projects, whether it be a
                    custom lamp, a fancy Christmas tree, or even a cosplay costume. Simply plug the device in and it
                    works! No programming necessary. Easily change LED strip length and mode with the onboard buttons.
                    For more advanced users, you can create your own cool lighting effects using the Arduino IDE.
                    <br />
                    <br />
                    We offer 2 options: single SunriseRGB controllers for those who already have LED strips and a
                    starter kit with a 10 ft LED strip and power supply included.
                </Text>

                <SimpleGrid
                    spacing={8}
                    // templateColumns={['1fr', '1fr', '1fr', 'repeat(auto-fill, minmax(300px, 1fr))']}
                    columns={{base: 2, md: 2, lg: 3}}
                    justify="center"
                    py={8}
                    mb={16}
                    // pt={16}
                >
                    {
                        colorPics.map((pic, index) =>
                            <Image key={index} src={pic} w="200px" h="200px" objectFit="cover" shadow="2xl" rounded="1rem"/>
                        )
                    }

                </SimpleGrid>


                <TitleWithColorBar text="Features" textSize={36} color="#980000"/>

                <SimpleGrid
                    spacing={8}
                    // templateColumns={['1fr', '1fr', '1fr', 'repeat(auto-fill, minmax(300px, 1fr))']}
                    columns={{base: 1, md: 2, lg: 2}}
                    justify="center"
                    py={8}
                    px={{base: 2, md: 8 }}
                    pt={0}
                    mb={16}
                >

                {
                    features.map((feature, index) =>

                        <HStack key={index} spacing="16px">
                            <Image src={feature.icon} w="100px"/>
                            <Text maxW={{md: '300px' }}>{feature.description}</Text>
                        </HStack>
                    )
                }

                </SimpleGrid>

                <TitleWithColorBar text="Lighting Options" textSize={36} color="#980000"/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>We’ve preprogrammed the SunriseRGB controller with a variety of lighting effects you can change with a click of a button. Easily change the mode and LED count with the hidden onboard buttons: </Text>

                <Stack
                    direction={{base: "column", lg: "row"}}
                    spacing={4}
                    alignItems="center"
                    alignContent="center"
                    // maxW="100%"
                    mb={12}
                >
                    <Image src={Buttons} w={{base: "100%", lg: '50%' }} maxW={{ md: '500px' }} shadow="2xl" rounded="1rem"/>

                    <VStack
                        // maxW="80%"
                        w={{base: "100%", lg: "50%"}}
                        alignContent="justify-start"
                    >
                        <Text>
                            Button 1: <br />
                            Press to increase LED count <br />
                            Hold to power off
                            <br />
                            <Box display={{base: "none", lg: "block" }}>
                                <br />
                            </Box>
                            <br />
                            Button 2: <br />
                            Press to change mode <br />
                            Hold while pressing button 1 to decrease LED count
                        </Text>
                    </VStack>
                </Stack>

                <SimpleGrid
                    spacingX={8}
                    spacingY={4}
                    // templateColumns={['1fr', '1fr', '1fr', 'repeat(auto-fill, minmax(300px, 1fr))']}
                    columns={{base: 2, md: 2, lg: 2}}
                    justify="center"
                    py={8}
                    px={{base: 2, md: 8 }}
                    pt={0}
                    mb={0}
                >

                    {
                        lightingModes.map((mode, index) =>

                            <Stack
                                key={index}
                                spacing={{"base": "2px", md: "8px" }}
                                direction={{base: "column", md: "row"}}
                                alignItems="center"
                            >
                                <Image src={mode.icon} w="100px"/>
                                <Text >{mode.description}</Text>
                            </Stack>
                        )
                    }

                </SimpleGrid>

                <Text
                    w={{base: mobileTextWidth, md: "75%"}}
                    mb={24}
                >
                    Want more options? Write a program in the Arduino IDE with your custom lighting options or use a Neopixel code generator, then upload it to SunriseRGB!
                </Text>



                <TitleWithColorBar text="Hardware" textSize={36} color="#980000"/>

                <SimpleGrid
                    spacing={8}
                    // templateColumns={['1fr', '1fr', '1fr', 'repeat(auto-fill, minmax(300px, 1fr))']}
                    columns={{base: 1, md: 2, lg: 2}}
                    justify="center"
                    py={8}
                    px={{base: 2, md: 8 }}
                    pt={0}
                    mb={16}
                >

                    {
                        hardware.map((item, index) =>

                            <HStack key={index} spacing="16px">
                                <Image src={item.icon} w="100px"/>
                                <Text maxW="250px">{item.description}</Text>
                            </HStack>
                        )
                    }

                </SimpleGrid>

                <TitleWithColorBar text="Connection Options" textSize={36} color="#980000"/>

                <Text w={{base: mobileTextWidth, md: "75%"}}>
                    SunriseRGB easily connects to off the shelf LED strips via the included male to male header:
                </Text>

                <Stack
                    direction={{base: "column", lg: "row"}}
                    my={8}
                    spacing={8}
                    alignItems="center"
                    // w={{base: "100%"}}
                >
                    <Image src={ConnectionOptions1} w={{base: "100%", md: "100%", lg: "50%"}} maxW={{ md: '400px' }} shadow="2xl" rounded="1rem"/>
                    <Image src={ConnectionOptions2} w={{base: "100%", md: "100%", lg: "50%"}} maxW={{ md: '400px' }} shadow="2xl" rounded="1rem"/>

                </Stack>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    Alternatively, you can solder the header directly to a LED strip, then plug it in:
                </Text>

                <Image src={ConnectionOptions3} w={{base: "100%", md: "60%"}} maxW={{md: '600px' }} shadow="2xl" rounded="1rem" mb={16}/>


                <TitleWithColorBar text="Compact Size" textSize={36} color="#980000"/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    We designed SunriseRGB to easily fit at the ends of LED strips. It measures only 13mm by 41mm and is an impressive 8mm thick! This makes it easy to hide whether if its connected directly to a LED strip or not.
                </Text>

                <Image src={CompactSize1} w={{base: "100%", md: "60%"}} maxW="600px" shadow="2xl" rounded="1rem" mb={16}/>



                {/* Open Source */}

                <TitleWithColorBar text="Open Source" textSize={36} color="#980000"/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    To help facilitate programming, our RGB controller runs the open source Micronucleus bootloader and can be programmed through the Arduino IDE. We will also include our schematic on our website to help programmers configure the switches on the device.
                </Text>

                <Image src={OpenSource1} w={{base: "100%", md: "60%"}} maxW="600px" shadow="2xl" rounded="1rem" mb={16}/>


                {/* Made in USA */}

                <TitleWithColorBar text="Made in USA" textSize={36} color="#980000"/>


                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    Proudly designed and assembled in the US, from 90% US sourced components.
                </Text>

                <Image src={MadeInUsa} w={{base: "100%", md: "60%"}} maxW="600px" shadow="2xl" rounded="1rem" mb={16}/>


                {/* Project Ideas */}

                <TitleWithColorBar text="Project Ideas" textSize={36} color="#980000"/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    To give you some ideas about what you can use our LED controller for, we built a few cool examples:
                </Text>



                {/*<SimpleGrid*/}
                {/*    columns={{base: 1, lg: 2}}*/}
                {/*    alignItems="center"*/}
                {/*    alignContent="center"*/}
                {/*>*/}
                {/*    {*/}
                {/*        projectIdeas.slice(1, projectIdeas.length).map((example, index) =>*/}
                {/*            <Image src={example} maxW="500px" maxH="500px" shadow="2xl" rounded="1rem" mb={16}/>*/}
                {/*        )*/}
                {/*    }*/}

                {/*</SimpleGrid>*/}




                <Image src={Tv} w={{base: "100%", md: "60%"}} maxW="600px" shadow="2xl" rounded="1rem" mb={16}/>

                <Stack
                    direction={{base: "column", lg: "row"}}
                    alignItems="center"
                    spacing={{base: 0, lg: 16}}
                >
                    <Image src={Desktop} w={{base: "100%", lg: "60%"}} maxW={{ md: '400px' }} shadow="2xl" rounded="1rem" mb={16}/>
                    <Image src={ChristmasTree} w={{base: "100%", lg: "60%"}} maxW={{ md: '400px' }} shadow="2xl" rounded="1rem" mb={16}/>
                </Stack>

                <Stack
                    direction={{base: "column", xl: "row"}}
                    alignItems="center"
                    spacing={{base: 0, xl: 16}}
                    mb={16}
                >
                    <Image src={Microwave} w={{base: "100%", lg: "100%"}} maxW={{ md: '500px' }}  shadow="2xl" rounded="1rem" mb={16}/>
                    <Image src={Bike} w={{base: "100%", lg: "100%"}} maxW={{ md: '500px' }}  shadow="2xl" rounded="1rem" mb={16}/>
                </Stack>

                {/* Design Process */}

                <TitleWithColorBar text="Design Process" textSize={36} color="#980000"/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    We initially developed the idea for this product when one of us was looking for a way to add
                    addressable LED lights to his room, but couldn’t find any off the shelf LED controller that was both
                    compact, Arduino compatible, and aesthetically pleasing. This gave us inspiration in developing a
                    better RGB controller.
                    <br />
                    <br />
                    After we initially decided to begin work on our product, we first built several prototypes out of
                    through hole components and perfboard. This helped us find any errors we made in the schematic and
                    helped us finalize our component selection for the PCB prototypes.
                </Text>

                <VStack
                    mb={12}
                >
                    <Image src={Board} w={{base: "100%", lg: "100%"}} maxW={{ md: '600px' }} maxH="500px" shadow="2xl" rounded="1rem"/>
                    <Text mt="-2px" fontSize="12px">Our first prototype</Text>
                </VStack>


                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    We moved on to designing and ordering our circuit boards we planned to use in the final product.
                    However, during this time we ran into a major hurdle with the circuit boards not functioning as we
                    intended. It took us quite a while to figure out the source of the problem, and we completely
                    redesigned the circuit board from scratch afterwards.
                    <br />
                    <br />
                    The second batch of circuit boards were fully functional and we put them through rigorous testing to
                    make sure there were no problems with them. We then designed a third batch with some minor changes
                    for manufacturing.
                </Text>

                <Stack
                    direction={{base: "column", lg: "row"}}
                    alignItems="center"
                    spacing={{base: 0, lg: 16}}
                    mb={{base: 0, lg: 0 }}
                >
                    <VStack
                        mb={12}
                    >
                        <Image src={EarlyPrototypes} w={{base: "100%", lg: "100%"}} maxW={{ md: '400px' }}  shadow="2xl" rounded="1rem"/>
                        <Text mt="-2px" fontSize="12px">Early circuit board prototypes</Text>
                    </VStack>

                    <VStack
                        mb={12}
                    >
                        <Image src={Revision2} w={{base: "100%", lg: "100%"}} maxW={{ md: '500px' }}  shadow="2xl" rounded="1rem"/>
                        <Text mt="-2px" fontSize="12px">A revision 2 prototype</Text>
                    </VStack>

                </Stack>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    Another important part of our product was the casing. Our original casing design had a boxy design
                    with a separate cover where the buttons were mounted. However, we weren’t satisfied with this
                    design’s thickness and aesthetics. We also worried about the structural integrity of the housing
                    during shipping and accidental impacts.
                    <br />
                    <br />
                    This led us to completely redesign the housing to meet these requirements. We switched from a cover
                    to a tube like design where the circuit board would be inserted and held in place with a plastic
                    pin. We replaced the buttons from the original design with a vinyl cover piece, shaving off 4 mm of
                    thickness and reducing the part count to 2 from 5. It’s also super sturdy and we weren’t able to
                    break it during testing. Best of all, we think the new design looks amazing!
                </Text>


                <Stack
                    direction={{base: "column", lg: "row"}}
                    alignItems="center"
                    spacing={{base: 0, lg: 16}}
                    mb={{base: 0, lg: 12 }}
                >
                    <VStack
                        mb={12}
                    >
                        <Image src={OriginalCasing} w={{base: "100%", lg: "100%"}} maxW={{ md: '500px' }} maxH="400px" shadow="2xl" rounded="1rem"/>
                        <Text mt="-2px" fontSize="12px">Early circuit board prototypes</Text>
                    </VStack>

                    <VStack
                        mb={12}
                    >
                        <Image src={NewCasing} w={{base: "100%", lg: "100%"}} maxW={{ md: '500px' }} maxH="400px" shadow="2xl" rounded="1rem"/>
                        <Text mt="-2px" fontSize="12px">A revision 2 prototype</Text>
                    </VStack>

                </Stack>

                {/* Product Options */}

                <TitleWithColorBar text="Product Options" textSize={36} color="#980000"/>


                {/*<VStack*/}
                {/*    spacing={8}*/}
                {/*>*/}
                {/*    {*/}
                {/*        productOptions.map((option, index) =>*/}
                {/*            <Card*/}
                {/*                direction={{ base: 'column', base: 'column', md: "row" }}*/}
                {/*                overflow='hidden'*/}
                {/*                variant='outline'*/}
                {/*                bg="#980000"*/}
                {/*                w={{base: "90%", md: "80%", lg: "80%"}}*/}
                {/*                maxW={{base: "450px", md: '3000px' }}*/}
                {/*                minH="200px"*/}
                {/*                key={index}*/}
                {/*                rounded="1rem"*/}
                {/*            >*/}
                {/*                <Image*/}
                {/*                    objectFit='cover'*/}
                {/*                    maxW={{ base: '100%', base: "300px", md: '250px' }}*/}
                {/*                    src={option.image}*/}
                {/*                    alt='Caffe Latte'*/}
                {/*                    // style={{alignSelf: { base: 'center', md: "start"}}}*/}
                {/*                    alignSelf={{ base: 'center', md: "center"}}*/}
                {/*                    mt={{base: 8, md: 0}}*/}
                {/*                    ml={{md: 4}}*/}
                {/*                    // shadow="2xl"*/}
                {/*                    rounded="1rem"*/}
                {/*                />*/}

                {/*                <Stack>*/}
                {/*                    <CardBody>*/}
                {/*                        <Heading size='lg' pr={{md: 4 }}>{option.title}</Heading>*/}

                {/*                        <Text py='2' pr={{md: 4 }}>*/}
                {/*                            {option.description}*/}
                {/*                        </Text>*/}
                {/*                    </CardBody>*/}

                {/*                </Stack>*/}
                {/*            </Card>*/}
                {/*        )*/}
                {/*    }*/}
                {/*</VStack>*/}

                <VStack
                    spacing={8}
                >
                    {
                        productOptions.map((option, index) =>
                            <Box
                                direction={{base: 'column', md: "row" }}
                                // overflow='hidden'
                                variant='outline'
                                bg="#980000"
                                w={{base: "100%", md: "100%", lg: "90%"}}
                                maxW={{base: "500px", md: '3000px' }}
                                minH="200px"
                                key={index}
                                rounded="1rem"
                                p={{base: 8, md: 6}}
                                shadow="2xl"

                                // rounded="2rem"
                                // // borderRadius="lg"  // Adjust the border radius as needed
                                // border="6px solid #f1c231"
                                // backgroundColor="transparent"
                                // p={4}  // Add padding as needed
                                //
                                // w={{base: '90%', md: "100%" }}
                                // maxW={{base: "500px", lg: "300px"}}
                                // alignItems="center"
                                // alignContent="center"
                                // key={index}

                            >
                                <Stack
                                    direction={{base: "column", md: "row"}}
                                    spacing={8}
                                >
                                    <Image
                                        // objectFit='cover'
                                        w={{base: '100%'}}
                                        maxW={{base: "450px", md: '250px' }}
                                        src={option.image}
                                        alt='Caffe Latte'
                                        // style={{alignSelf: { base: 'center', md: "start"}}}
                                        alignSelf={{ base: 'center', md: "center"}}
                                        // mt={{base: 8, md: 0}}
                                        // ml={{md: 4}}
                                        shadow="2xl"
                                        rounded="1rem"
                                    />

                                    {/*<VStack>*/}
                                    <Box>
                                        <Heading size='lg' pr={{md: 4 }} mb={2}>{option.title}</Heading>

                                            <Text py='2' pr={{md: 4 }}>
                                                {option.description}
                                            </Text>

                                    </Box>

                                    {/*</VStack>*/}

                                </Stack>

                            </Box>
                        )
                    }
                </VStack>




                <Text mt="100px">© AnomalyWC 2023 - △ ☽ α</Text>

            </Flex>
        </>
    );
}