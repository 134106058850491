import React, { useRef, useEffect } from 'react';
import HomePage from './homepage';
// import About from './about';
// import WhyJoin from './whyjoin';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Products from './products';
import About from './about';
import SunriseRGB from './sunrisergb';
import Z3Leo from './z3leo';
import OmniDial from './omnidial';

// import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export default function Main() {
    const containerRef = useRef(null);

    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<HomePage />}></Route>
                <Route path="products" element={<Products />}></Route>
                <Route path="about" element={<About />}></Route>
                <Route path="products/sunrisergb" element={<SunriseRGB/>}></Route>
                <Route path="products/z3leo" element={<Z3Leo/>}></Route>
                <Route path="products/omnidial" element={<OmniDial/>}></Route>
            </Routes>
        </BrowserRouter>
    );
}



