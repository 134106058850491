import React from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text, Container, Grid, SimpleGrid, Card, CardBody, CardHeader, CardFooter, Center, HStack, VStack,
} from '@chakra-ui/react';

import Navbar from './navbar';

import Splash from '../images/splash.png';
import Kickstarter from '../images/kickstarter.png';
import Linkedin from '../images/linkedin.png';

export default function About() {
    return (
        <>
            <Navbar />

            <Flex
                align="center"
                justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
                direction={{ base: 'column' }}
                wrap="no-wrap"
                minH="70vh"
                px={{base: 4, md: 8}}
                mb={16}
                mx="auto"
                maxW={{base: "600px", md: "unset"}}
            >
                <Stack
                    spacing="48px"
                    direction={{base: "column", lg: "row"}}
                    alignItems="center"
                >
                    <VStack spacing="24px" maxWidth={{md: "500px", lg: '500px' }} w={{base: "100%", lg: "90%"}}>
                        <Image src={Splash} shadow="2xl" rounded="1rem" />

                        <HStack spacing="24px">
                            <Link to="https://www.kickstarter.com/profile/anomalywc/created" target="_blank"
                                  rel="noopener noreferrer">
                                <Image src={Kickstarter} w="50px" />
                            </Link>

                            <Link to="https://www.linkedin.com/company/anomalywc/" target="_blank"
                                  rel="noopener noreferrer">
                                <Image src={Linkedin} w="50px" />
                            </Link>
                        </HStack>

                    </VStack>

                    <Text width={{base: "100%", md: '75%' }} maxW={{ base: "750px", lg: '500px' }}>
                        AnomalyWC is a Minnesota-based design company focused on making products that are both easy to
                        use and aesthetically pleasing. We have successfully funded two Kickstarter projects and
                        continue to design consumer focused products. Our engineering team both develops and
                        manufactures our products in house so we can assure they meet our quality standards. We work
                        with top suppliers to ensure the components we use are high quality and durable, and our quality
                        control team tests every item we ship out. Our company strives to incorporate customer feedback
                        into our designs to create the most user friendly products we can.
                    </Text>

                </Stack>

                <Text mt="24px">© AnomalyWC 2023 - △ ☽ α</Text>

            </Flex>
        </>
    );
}