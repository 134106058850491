import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: '#000000', // Your desired background color
                fontFamily: 'Nunito, sans-serif', // Set Nunito as the font for body text
                color: "#ffffff",
            },
        },
    },
    fonts: {
        body: 'system-ui, sans-serif', // Use the default sans-serif font for headers
        heading: 'Oswald, sans-serif', // Use the default sans-serif font for headings
    },
    components: {
        Text: {
            baseStyle: {
                maxW: '750px', // Set the maximum width for all Text components
                color: "#ffffff",
            },
        },
    },
});

export default theme;