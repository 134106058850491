import React from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text,
    Container,
    Grid,
    SimpleGrid,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Center,
    HStack,
    VStack,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';

import Navbar from './navbar';

import Kickstarter from '../images/kickstarter.png';
import TitleWithColorBar from './titlewithcolorbar';
// import GitHub from '../images/github.png';

const redGradient = {
    // backgroundImage: 'linear-gradient(to right, green, white, green)',
    backgroundImage: 'linear-gradient(to right, red, orange, yellow)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
};

import OmniDialVertical from "../images/omnidialvertical.png";

import DialModes from "../images/omnidial/modes.png";

import ModeVolume from "../images/omnidial/modevolume.png";
import ModeBrightness from "../images/omnidial/modebrightness.png";
import ModeScrub from "../images/omnidial/modescrub.png";
import ModeSkip from "../images/omnidial/modeskip.png";
import ModeRight from "../images/omnidial/moderight.png";
import ModeScroll from "../images/omnidial/modescroll.png";

const modes = [
    {"icon": ModeVolume, "description": "Volume Up/Down"},
    {"icon": ModeBrightness, "description": "Brightness Up/Down"},
    {"icon": ModeScrub, "description": "Scrub Track Forwards/Backwards"},
    {"icon": ModeSkip, "description": "Skip Track Forwards/Backwards"},
    {"icon": ModeRight, "description": "Arrow Right/Left"},
    {"icon": ModeScroll, "description": "Scroll Up/Down"},
];

const topLinks = [
    {"icon": Kickstarter, "title": "Kickstarter", "url": "https://www.kickstarter.com/projects/anomalywc/omni-dial-a-more-intuitive-way-to-use-your-computer"}
];

const modeExplanations = [
    {"title": "Volume", "description": "Adjusts computer volume"},
    {"title": "Brightness", "description": "Adjusts computer brightness"},
    {"title": "Scrub Track", "description": "Moves position forwards and backwards in songs and videos"},
    {"title": "Skip Track", "description": "Skips to the previous or next song/video"},
    {"title": "Arrow ", "description": "Moves the cursor left and right using the arrow keys"},
    {"title": "Scroll", "description": "Scrolls up and down"},
];


// import ChangingModes from "../images/omnidial/df0a831c132813a42b9432f0b84cfe22_original.gif";

import UsageHorizontal from "../images/omnidial/usagehorinzontal.png";
import UsageVertical from "../images/omnidial/usagevertical.png";

// import FineAdjustment from "../images/omnidial/863c48901c5f3e0de6a365c7c14ddb72_original.gif";

import LightingModes from "../images/omnidial/lightingmodes.png";

// import AdjustVolume from "../images/omnidial/32452af531ba2ed7b9bd105fd7ffb55e_original-1.gif";
// import AdjustBrightness from "../images/omnidial/ff4bbf22fc8175698cbe4386e264f83c_original.gif";
// import ScrubTrack from "../images/omnidial/36a8543afd1004550f61850ebc942ec8_original.gif";
// import SkipTrack from "../images/omnidial/33ffe98cd07d853353b85674e1dd39db_original.gif";
// import Arrow from "../images/omnidial/7dc49968e27eee4528b1da88ef16c41e_original.gif";
// import Scroll from "../images/omnidial/946bf2d293e56a2fa98ff862c10ce3f8_original.gif";
//
// const featureDemos = [
//     {"title": "Volume", "description": "Adjust the volume of your computer and audio devices!", "image": AdjustVolume},
//     {"title": "Brightness", "description": "Adjust the brightness of your computer and connected monitors!", "image": AdjustBrightness},
//     {"title": "Scrub Track", "description": "Move a few seconds forwards or backwards through videos and music, such as Spotify, Youtube, and more!", "image": ScrubTrack},
//     {"title": "Skip Track", "description": "Skip to the previous or next song/video in a playlist on Spotify, Apple Music, and other music/video apps.", "image": SkipTrack},
//     {"title": "Arrow", "description": "Presses the left or right arrow, depending on the direction you swipe. Useful for moving the cursor through Word documents, code, and other large sections of text.", "image": Arrow},
//     {"title": "Scroll", "description": "Scrolls up and down in any application, like a mouse scroll wheel.", "image": Scroll},
// ]

const lighting = [
    {"icon": ModeVolume, "description": "Volume - Red"},
    {"icon": ModeBrightness, "description": "Brightness - Green"},
    {"icon": ModeScrub, "description": "Scrub Track - Orange"},
    {"icon": ModeSkip, "description": "Skip Track - Yellow"},
    {"icon": ModeRight, "description": "Arrow - Purple"},
    {"icon": ModeScroll, "description": "Scroll Up/Down"},
];


import Hardware1 from "../images/omnidial/hardware1.png";
import Hardware2 from "../images/omnidial/hardware2.png";
import Hardware3 from "../images/omnidial/hardware3.png";
import Compatibility1 from "../images/omnidial/compatibility1.png";
import America from "../images/omnidial/america.png";

const hardwareAndCompatibility = [
    {"icon": Hardware1, "description": "Powerful ARM processor allows for responsive touch inputs"},
    {"icon": Hardware2, "description": "Easily connect to desktops and laptops via USB C"},
    {"icon": Hardware3, "description": "Six sensitive capacitive touch pads"},
    {"icon": Compatibility1, "description": (
            <Text color="#f1c231">
                Fully compatible with Windows and MacOS
                <br />
                <br />
                Compatible with Linux distributions with media key support
            </Text>
        )},
];

import InAction1 from "../images/omnidial/inaction1.png";
import InAction2 from "../images/omnidial/inaction2.png";
import Development1 from "../images/omnidial/development1.png";
import Development2 from "../images/omnidial/development2.png";
import Development3 from "../images/omnidial/development3.png";


const inActionAndDevelopmentProcess = [
    {"image": InAction1, "caption": "Omni Dial connected to a Mac", "description": "Put Omni Dial next to your laptop trackpad for quick adjustments while moving the cursor!"},
    {"image": InAction2, "caption": "Omni Dial with a keyboard", "description": "For quick scrolling or moving the cursor while typing, put Omni Dial to the left of your keyboard!"},
    {"image": Development1, "caption": "The first Omni Dial testbed", "description": "After conceptualizing the idea of using a rotary touch surface for computer controls, our team at AnomalyWC began development of the Omni Dial in July of 2021. Initially, in order to test the feasibility of the swipe controls, we set up a cardboard disk with strips of copper tape to simulate the touch pads of our intended design. We built this testbed on a breadboard with an off the shelf capacitive sensing module and microcontroller. This let us start development of the embedded software for the Omni Dial. Our proof of concept device functioned very well, and we were impressed by how easy it was to use."},
    {"image": Development2, "caption": "Our first PCB prototypes and 3D printed housings", "description": "After the success of the testbed, our team moved on to designing a custom circuit board for the Omni Dial. Our first design had eight touch pads instead of the six found on the final version. It also had a center mounted indicator LED, and was an inch larger in diameter than the final version.  This design helped us finalize the software that ran on the Omni Dial, as we were able to account for potential issues such as two of the touch pads being touched at once, or swiping around the pads too quickly. We were also able to test out housing design, which we specifically engineered to use as little plastic as possible while still providing a solid surface for the touch input pads."},
    {"image": Development3, "caption": "Our final Omni Dial design and housing", "description": "We gave our original design to a team of beta testers, who gave us several helpful design suggestions, which were implemented in our second and final circuit board design. We decided to reduce the Omni Dial to six touch inputs, as our testers found the last two modes to not be very useful. We also removed the center mounted LED, replacing it with a translucent chassis which glows from an internal LED. This was done as our testers found that their fingers sometimes made contact with the LED, impeding use of the device. Finally, we shrunk the device down to a two inch diameter, extending the touch pads to where the indicator LED had previously been. We found that when rapidly swiping in a circular motion, sometimes your finger would travel over center of the device, which had no touch surfaces. By extending the touch pads and shrinking the diameter of the Omni Dial, we were able to effectively eliminate this dead zone.  We also redesigned the housing to account for the smaller diameter, and improved the fitment around the circuit board and USB C port."},
];


import Rewards1 from "../images/omnidial/rewards1.png";
import Rewards2 from "../images/omnidial/rewards2.png";
import Rewards3 from "../images/omnidial/rewards3.png";

const rewardsData = [
    {"image": Rewards1, "title": "OMNI DIAL: Early Bird - $25", "point1": "Includes 1 Omni Dial", "point2": "FREE SHIPPING to USA", "point3": "Ships August 2022"},
    {"image": Rewards2, "title": (
        <>
            <Text color="#f1c231">OMNI DIAL - $25</Text>

            <Box display={{base: "None", lg: "block"}}>
                <br />
            </Box>
        </>
            ), "point1": "Includes 1 Omni Dial", "point2": "$5 Shipping to USA", "point3": "Ships December 2022"},
    {"image": Rewards3, "title": "OMNI DIAL: Triple Pack - $75", "point1": "Includes 3 Omni Dials", "point2": "FREE SHIPPING to USA", "point3": "Ships December 2022"},
];

const mobileTextWidth = "100%";

export default function OmniDial() {
    return (
        <>
            <Navbar />

            <Flex
                align="center"
                justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
                direction={{ base: 'column' }}
                wrap="no-wrap"
                minH="70vh"
                px={{base: 4, md: 8}}
                mb={16}
                mx="auto"
                maxW={{base: "600px", md: "1200px"}}
            >

                <Heading fontSize={{base: "60px", md: '80px' }} mb={16} sx={redGradient}>Omni Dial</Heading>


                <Stack
                    // direction={{base: "row", md: "row"}}
                    // columns={{base: 2, md: 4}}
                    spacing={{base: 8, md: 16}}
                    mb={8}
                >

                    {
                        topLinks.map((link, index) =>
                            <Stack
                                direction={{base: "column", md: "column"}}
                                alignItems="center"
                            >

                                <Link to={link.url} target="_blank" rel="noopener noreferrer">
                                    <Image src={link.icon} w="60px"/>
                                </Link>

                                <Text>{link.title}</Text>

                            </Stack>

                        )
                    }

                </Stack>


                <TitleWithColorBar text="About" textSize={36} color="#f1c231"/>


                {/*<Stack*/}
                {/*    direction={{base: "column", lg: "row"}}*/}
                {/*    spacing={{ base: 8, lg: 16 }}*/}
                {/*    alignItems="center"*/}
                {/*    alignContent="center"*/}
                {/*    maxW={{ base: '90%', lg: '80%' }} // Set max width based on the Text maxW*/}
                {/*    mx="auto" // Center the Stack horizontally*/}
                {/*    mb={16}*/}
                {/*>*/}

                {/*    <Image src={OmniDialVertical} w={{base: "90%", lg: "300px"}} maxW="400px" shadow="2xl" rounded="1rem"/>*/}

                {/*    <Text*/}
                {/*        maxW={{ base: '90%', lg: '100%' }}*/}
                {/*    >*/}
                {/*        Omni Dial is a new peripheral for your computer, designed to complement the mouse and keyboard*/}
                {/*        by adding a quick way to adjust computer functions. We all love the traditional mouse and*/}
                {/*        keyboard, yet there is a gap in their abilities: clicking through several menus to adjust the*/}
                {/*        brightness on your computer or repeatedly pressing the right arrow to move the cursor while*/}
                {/*        programming just isn't very fast or ergonomic.*/}
                {/*        <br />*/}
                {/*        <br />*/}
                {/*        Our team at AnomalyWC designed a device to bridge this gap and help streamline your digital*/}
                {/*        workflow. We wanted to combine the intuitive touch controls of a trackpad with the precision of*/}
                {/*        a physical knob, and from this we came up with the circular touch interface that serves as the*/}
                {/*        core of Omni Dial. With a clockwise or counterclockwise swipe of your finger, Omni Dial lets you*/}
                {/*        conveniently adjust brightness, volume, skip through songs, and more!*/}
                {/*    </Text>*/}

                {/*</Stack>*/}

                <Stack
                    direction={{base: "column", lg: "row"}}
                    spacing={{ base: 8, lg: 16 }}
                    alignItems="center"
                    alignContent="center"
                    maxW={{ base: '100%', lg: '80%' }} // Set max width based on the Text maxW
                    mx="auto" // Center the Stack horizontally
                    mb={16}
                >

                    <Image src={OmniDialVertical} w={{base: "100%", lg: "100%"}} maxW={{md: "400px", lg: '400px' }} shadow="2xl" rounded="1rem"/>

                    <Text
                        w={{base: "100%", md: "75%", lg: '100%'}}
                        maxW={{ lg: "500px"}}
                    >
                        Omni Dial is a new peripheral for your computer, designed to complement the mouse and keyboard
                        by adding a quick way to adjust computer functions. We all love the traditional mouse and
                        keyboard, yet there is a gap in their abilities: clicking through several menus to adjust the
                        brightness on your computer or repeatedly pressing the right arrow to move the cursor while
                        programming just isn't very fast or ergonomic.
                        <br />
                        <br />
                        Our team at AnomalyWC designed a device to bridge this gap and help streamline your digital
                        workflow. We wanted to combine the intuitive touch controls of a trackpad with the precision of
                        a physical knob, and from this we came up with the circular touch interface that serves as the
                        core of Omni Dial. With a clockwise or counterclockwise swipe of your finger, Omni Dial lets you
                        conveniently adjust brightness, volume, skip through songs, and more!
                    </Text>

                </Stack>



                <TitleWithColorBar text="Design" textSize={36} color="#f1c231"/>


                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    <Text fontWeight="bold" fontSize="24px">Modes</Text>
                    <br/>
                    We took six of the most commonly used computer shortcuts/features, and put them on Omni Dial as adjustable modes to help you increase your productivity. Each of the touch pads on Omni Dial are labeled with their respective modes, located at the end of each touch pad.
                </Text>

                <Stack
                    direction={{base: "column", md: "row"}}
                    alignItems="center"
                    spacing={{base: 12, md: 16, lg: 24 }}
                    mb={8}
                >
                    <Image src={DialModes} w="300px"/>


                    <Box
                        rounded="2rem"
                        // borderRadius="lg"  // Adjust the border radius as needed
                        border="6px solid #f1c231"
                        backgroundColor="transparent"
                        p={4}  // Add padding as needed

                        w={{base: '100%', md: "50%" }}
                        maxW={{base: "500px"}}
                        alignItems="center"
                        alignContent="center"
                    >
                        <Text
                            fontSize="24px"
                            fontWeight="bold"
                            color="#f1c231"
                            textAlign="center"
                            mb={1}
                        >
                            MODES
                        </Text>

                        <SimpleGrid
                            columns={1}
                            spacingY={0}
                        >
                            {
                                modes.map((mode, index) =>
                                    // <HStack key={index}>
                                    <HStack>

                                        {/* TODO: The scrub button is too large */}

                                        <Image src={mode.icon} w="50px" h="50px"/>
                                        <Text color="#f1c231">{mode.description}</Text>
                                    </HStack>

                                )
                            }

                        </SimpleGrid>


                    </Box>

                </Stack>


                <Text w={{base: mobileTextWidth, md: "75%"}}>
                    <UnorderedList
                        // w={{base: "80%", md: "100%"}}
                        // w={}
                        // alignSelf="center"
                    >

                        {
                            modeExplanations.map((mode, index) =>
                                <ListItem fontSize="16px"><em style={{fontStyle: "normal", fontWeight: "bold"}}>{mode.title}</em> - {mode.description}</ListItem>
                            )
                        }

                        <br/>
                    </UnorderedList>

                </Text>


                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    See the <em style={{ fontStyle: 'normal', fontWeight: 'bold' }}>Feature Demos</em> section below to
                    see these features in action!
                    <br />
                    <br />
                    To switch between the modes, simply press and hold the corresponding touch button for three seconds,
                    and the indicator light will let you know that the mode has changed:
                </Text>


                {/*<VStack*/}
                {/*    w="100%"*/}
                {/*    spacing={0}*/}
                {/*    mb={8}*/}
                {/*>*/}
                {/*    <Image src={ChangingModes} w={{base: "80%", md: "60%"}} maxW="450px" shadow="2xl" rounded="1rem"/>*/}
                {/*    <Text mt="10px" fontSize="16px"><em>Changing Modes</em></Text>*/}
                {/*</VStack>*/}


                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    <Text fontWeight="bold" fontSize="24px">Adjusting Modes</Text>
                    <br/>

                    To adjust any of the modes, simply use your finger to swipe counterclockwise to decrement and clockwise to increment. Each touch pad your finger moves across will adjust the current mode up or down by one. For example, swiping one touch pad clockwise will increase the volume by one click, while swiping your finger a full circle clockwise will increase the volume by 6 clicks.
                </Text>



                <Box
                    rounded="2rem"
                    // borderRadius="lg"  // Adjust the border radius as needed
                    border="6px solid #f1c231"
                    backgroundColor="transparent"
                    p={4}  // Add padding as needed
                    w={{base: "100%", md: '95%', lg: "100%" }}
                    maxW="750px"
                    mb={8}
                >
                    <Text
                        fontSize="24px"
                        fontWeight="bold"
                        color="#f1c231"
                        textAlign="left"
                        ml={{ base: 4, md: 8 }}
                    >
                        USAGE
                    </Text>

                    <Image src={UsageVertical} display={{base: "block", md: "None"}} pr="16%"/>
                    <Image src={UsageHorizontal} display={{base: "None", md: "block"}} pb="3%"/>

                </Box>

                {/*<Image src={FineAdjustment} w={{base: "80%", md: "60%"}} maxW="450px" shadow="2xl" rounded="1rem" mb={8}/>*/}

                {/*<Text w={{base: mobileTextWidth, md: "75%"}} mb={16}>*/}
                {/*    For fine adjustments, simply swipe your finger repeatedly on the same two touch pads, which will increment or decrement the selected mode by one.*/}
                {/*</Text>*/}


                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    <Text fontWeight="bold" fontSize="24px">Mode Indicator Light</Text>
                    <br/>

                    Omni Dial will glow a different color depending on what mode it is currently in. The colors and the corresponding modes are labeled for each touch pad on the device.
                </Text>

                <Stack
                    direction={{base: "column", md: "row"}}
                    alignItems="center"
                    spacing={{base: 12, md: 16, lg: 24 }}
                    mb={0}
                    mb={16}
                >
                    <Image src={LightingModes} w="300px"/>


                    <Box
                        rounded="2rem"
                        // borderRadius="lg"  // Adjust the border radius as needed
                        border="6px solid #f1c231"
                        backgroundColor="transparent"
                        p={4}  // Add padding as needed

                        w={{base: '100%', md: "100%" }}
                        maxW={{base: "500px"}}
                        alignItems="center"
                        alignContent="center"
                    >
                        <Text
                            fontSize="24px"
                            fontWeight="bold"
                            color="#f1c231"
                            textAlign="center"
                            mb={1}
                        >
                            LIGHTING
                        </Text>

                        <SimpleGrid
                            columns={1}
                            spacingY={0}
                            px={8}
                        >
                            {
                                lighting.map((mode, index) =>
                                    // <HStack key={index}>
                                    <HStack>

                                        {/* TODO: The scrub button is too large */}

                                        <Image src={mode.icon} w="50px" h="50px"/>
                                        <Text color="#f1c231">{mode.description}</Text>
                                    </HStack>

                                )
                            }

                        </SimpleGrid>


                    </Box>

                </Stack>

                {/*<Text color="red" mb={16}>TODO: Do this after the background removed assets are put in the google drive</Text>*/}


                {/*<TitleWithColorBar text="Feature Demos" textSize={36} color="#f1c231"/>*/}


                {/*<Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>*/}
                {/*    Our video demos below show how Omni Dial's six modes can fit into your daily workflow!*/}
                {/*</Text>*/}

                {/*{*/}
                {/*    featureDemos.map((feature, index) =>*/}
                {/*        <>*/}
                {/*            <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>*/}
                {/*                <Text fontWeight="bold" fontSize="24px">{feature.title}</Text>*/}
                {/*                <br />*/}

                {/*                {feature.description}*/}
                {/*            </Text>*/}

                {/*            <Image src={feature.image} w={{base: "80%", md: "60%"}} maxW="450px" shadow="2xl" rounded="1rem" mb={8}/>*/}
                {/*        </>*/}

                {/*    )*/}
                {/*}*/}


                <TitleWithColorBar text="Hardware" textSize={36} color="#f1c231" mb={8}/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    We designed Omni Dial from the ground up to be plug-and-play with different kinds of computers- Windows, Mac and Linux. Simply grab a USB C cable and plug Omni Dial in, and you're ready to go! Our powerful hardware and throughly tested software ensures Omni Dial always functions smoothly.
                </Text>


                <Stack
                    direction={{base: "column", lg: "row"}}
                    spacing={8}
                    alignItems={{base: 'center', lg: "unset"}}
                    // aligContent="center"
                    w={{base: "100%", lg: "100%"}}
                    maxW={{lg: "800px"}}
                    mb={16}
                >

                    <Box
                        rounded="2rem"
                        // borderRadius="lg"  // Adjust the border radius as needed
                        border="6px solid #f1c231"
                        backgroundColor="transparent"
                        p={4}  // Add padding as needed

                        w={{base: '100%', md: "90%" }}
                        maxW={{md: "500px"}}
                        alignItems="center"
                        alignContent="center"
                        mr={0}
                    >
                        <Text
                            fontSize="24px"
                            fontWeight="bold"
                            color="#f1c231"
                            textAlign="center"
                            mb={1}
                        >
                            HARDWARE
                        </Text>

                        <SimpleGrid
                            columns={1}
                            spacingY={0}
                        >
                            {
                                hardwareAndCompatibility.slice(0, 3).map((item, index) =>
                                    // <HStack key={index}>
                                    <HStack
                                        spacing={4}
                                    >

                                        <Image src={item.icon} w="75px" h="75px"/>
                                        <Text color="#f1c231">{item.description}</Text>
                                    </HStack>

                                )
                            }

                        </SimpleGrid>
                    </Box>


                    <VStack
                        spacing={8}
                        w="100%"
                    >
                        <Box
                            rounded="2rem"
                            // borderRadius="lg"  // Adjust the border radius as needed
                            border="6px solid #f1c231"
                            backgroundColor="transparent"
                            p={4}  // Add padding as needed

                            w={{base: '100%', md: "100%" }}
                            maxW={{md: "500px"}}
                            alignItems="center"
                            alignContent="center"
                        >
                            <Text
                                fontSize="24px"
                                fontWeight="bold"
                                color="#f1c231"
                                textAlign="center"
                                mb={1}
                            >
                                COMPATIBILITY
                            </Text>

                            <SimpleGrid
                                columns={1}
                                spacingY={0}
                            >
                                {
                                    hardwareAndCompatibility.slice(3, 4).map((item, index) =>
                                        // <HStack key={index}>
                                        <HStack
                                            spacing={4}
                                        >

                                            <Image src={item.icon} w="75px" h="75px"/>
                                            <Text color="#f1c231">{item.description}</Text>
                                        </HStack>

                                    )
                                }

                            </SimpleGrid>

                        </Box>

                        <Box
                            rounded="2rem"
                            // borderRadius="lg"  // Adjust the border radius as needed
                            border="6px solid #f1c231"
                            backgroundColor="transparent"
                            p={4}  // Add padding as needed

                            w={{base: '100%', md: "100%" }}
                            maxW={{md: "500px"}}
                            alignItems="center"
                            alignContent="center"
                        >

                            <HStack
                                alignItems="center"
                                alignContent="center"
                                // textAlign="center"
                                spacing={4}
                            >

                                <Image src={America} w="125px"/>

                                <Text
                                    fontSize="24px"
                                    fontWeight="bold"
                                    color="#f1c231"
                                    // textAlign="center"
                                    mb={1}
                                >
                                    MADE IN USA
                                </Text>

                            </HStack>

                        </Box>

                    </VStack>

                </Stack>


                <TitleWithColorBar text="Omni Dial in Action!" textSize={36} color="#f1c231" mb={8}/>

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    Omni Dial makes a valuable addition to your computer setup and can help you improve your efficiency while working. Although how you choose to use Omni Dial is up to your specific situation, we've made some examples of how we've fit it into our workspaces:
                </Text>

                {
                    inActionAndDevelopmentProcess.slice(0, 2).map((item) =>
                        <>
                            <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>{item.description}</Text>

                            <VStack
                                w="100%"
                                spacing={0}
                                mb={16}
                            >
                                <Image src={item.image} w={{base: "100%", md: "60%"}} maxW={{md: '550px' }} shadow="2xl" rounded="1rem"/>
                                <Text mt="10px" fontSize="16px"><em>{item.caption}</em></Text>
                            </VStack>

                        </>
                    )
                }

                <TitleWithColorBar text="Development Process" textSize={36} color="#f1c231" mb={8}/>

                {
                    inActionAndDevelopmentProcess.slice(2, inActionAndDevelopmentProcess.length).map((item) =>
                        <>
                            <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>{item.description}</Text>

                            <VStack
                                w="100%"
                                spacing={0}
                                mb={16}
                            >
                                <Image src={item.image} w={{base: "100%", md: "60%"}} maxW={{md: '550px' }} shadow="2xl" rounded="1rem"/>
                                <Text mt="10px" fontSize="16px"><em>{item.caption}</em></Text>
                            </VStack>

                        </>
                    )
                }

                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    Overall, our testing team was very pleased with the productivity improvements the redesigned Omni Dial brought to their daily workflow. We are really proud of what we created, and hope that you too can use Omni Dial to better your computing experience!
                </Text>


                <TitleWithColorBar text="Rewards" textSize={36} color="#f1c231" mb={8}/>

                <Stack
                    direction={{base: "column", lg: "row"}}
                    spacing={8}
                    mb={16}
                    alignItems={{base: 'center', lg: "unset"}}
                >

                    {
                        rewardsData.map((reward, index) =>
                            <Box
                                rounded="2rem"
                                // borderRadius="lg"  // Adjust the border radius as needed
                                border="6px solid #f1c231"
                                backgroundColor="transparent"
                                p={4}  // Add padding as needed

                                w={{base: '100%', md: "100%" }}
                                maxW={{base: "600px", lg: "300px"}}
                                alignItems="center"
                                alignContent="center"
                                key={index}
                            >

                                <VStack
                                    spacing={6}
                                >
                                    <Text
                                        fontSize="24px"
                                        fontWeight="bold"
                                        color="#f1c231"
                                        textAlign="center"
                                        mb={1}
                                    >
                                        {reward.title}
                                    </Text>

                                    <Image src={reward.image} shadow="2xl" rounded="1rem"
                                           w={{base: "100%"}}
                                           maxW={{base: "300px", lg: "225px"}}
                                           // mx={4}
                                    />

                                    <UnorderedList color="#f1c231">
                                        <ListItem>{reward.point1}</ListItem>
                                        <ListItem>{reward.point2}</ListItem>
                                        <ListItem>{reward.point3}</ListItem>
                                    </UnorderedList>

                                </VStack>


                            </Box>
                        )
                    }

                </Stack>


                <Text w={{base: mobileTextWidth, md: "75%"}} mb={8}>
                    Please note: USB C cables are not included with Omni Dial, but can be added on if you need one.
                </Text>


                <Text mt="100px">© AnomalyWC 2023 - △ ☽ α</Text>

            </Flex>
        </>
    );
}