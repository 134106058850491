
import React from "react"
import { Link } from "react-router-dom"

import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text, Container, Grid, SimpleGrid, Card, CardBody, CardHeader, CardFooter, Center, HStack, VStack,
} from '@chakra-ui/react';

import Navbar from './navbar';

import Sunrise from "../images/sunrisegrb/DSC_0775e.JPG";
import Omni from "../images/omnidial/rewards1.png";
import Z3 from "../images/z3leo/productoptions2.png";


let products = [
    {"title": "SunriseRGB", "description": "A compact, reprogrammable led strip controller", "url": "sunrisergb", "image": Sunrise, "background": "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)"},
    {"title": "Z3 Leo", "description": "A tiny microcontroller that fits inside a USB port", "url": "z3leo", "image": Z3, "background": "linear-gradient(to right, green, blue, purple)"},
    {"title": "Omni Dial", "description": "A more intuitive way to use your computer", "url": "omnidial", "image": Omni, "background": "linear-gradient(to right, red, orange, yellow)"}
];

export default function Products() {
    return (
        <>
            <Navbar/>

            <Flex
                align="center"
                justify={{ base: "center", md: "space-around", xl: "space-between" }}
                direction={{ base: "column"}}
                wrap="no-wrap"
                minH="70vh"
                px={{base: 4, md: 8}}
                mb={16}
            >

                <VStack
                    spacing={8}
                >
                    {
                        products.map((item, index) =>
                            <Box
                                direction={{ base: 'column', md: "row" }}
                                // overflow='hidden'
                                variant='outline'
                                bg="#0c5294"
                                // border={`6px solid white`}
                                // bg="transparent"
                                w={{base: "100%", md: "100%", lg: "800px"}}
                                maxW={{base: "600px", md: '3000px' }}
                                minH="200px"
                                key={index}
                                rounded="1rem"
                                p={{base: 8, md: 6}}
                                shadow="2xl"

                                // rounded="2rem"
                                // // borderRadius="lg"  // Adjust the border radius as needed
                                // border="6px solid #f1c231"
                                // backgroundColor="transparent"
                                // p={4}  // Add padding as needed
                                //
                                // w={{base: '90%', md: "100%" }}
                                // maxW={{base: "500px", lg: "300px"}}
                                // alignItems="center"
                                // alignContent="center"
                                // key={index}

                            >
                                <Stack
                                    direction={{base: "column", md: "row"}}
                                    spacing={8}
                                >
                                    <Image
                                        // objectFit='cover'
                                        maxW={{ base: "450px", md: '250px' }}
                                        w={{ base: '100%' }}
                                        src={item.image}
                                        alt='Caffe Latte'
                                        // style={{alignSelf: { base: 'center', md: "start"}}}
                                        alignSelf={{ base: 'center', md: "center"}}
                                        // mt={{base: 8, md: 0}}
                                        // ml={{md: 4}}
                                        shadow="2xl"
                                        rounded="1rem"
                                    />

                                    {/*<VStack>*/}
                                    <Box>
                                        <Heading size='lg' pr={{md: 4 }} mb={2}>{item.title}</Heading>

                                        <Text py='2' pr={{md: 4 }}>
                                            {item.description}
                                        </Text>

                                        <Link to={item.url}>
                                            <Button border="4px solid #ffffff" bg="transparent" borderRadius="2px" height="50px" mt={6} color="#ffffff">Learn More</Button>
                                        </Link>

                                    </Box>

                                    {/*</VStack>*/}

                                </Stack>

                            </Box>
                        )
                    }
                </VStack>

                <Text pt={8}>© AnomalyWC 2023 - △ ☽ α</Text>

            </Flex>
        </>
    );
}