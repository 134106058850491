import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import mainImage from '../images/copy.png';
import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text, Container, Grid, SimpleGrid, Card, CardBody, CardHeader,
} from '@chakra-ui/react';

// import logo from "../logo.svg";

import { InfoIcon, AddIcon } from '@chakra-ui/icons';
import { BsGiftFill } from 'react-icons/bs';

let cards = [
    {
        'icon': InfoIcon,
        'title': 'About Us',
        'description': 'AnomalyWC is an electronics design startup based out of Minnesota. We strive to create innovative products at an affordable price.',
    },
    {
        'icon': BsGiftFill,
        'title': 'Our Products',
        'description': 'We currently offer SunriseRGB, a compact addressable LED controller, Omni Dial, a touch peripheral for your computer, and Z3 Leo, a small microcontroller that fits into a USB port.',
    },
    {
        'icon': AddIcon,
        'title': 'Learn More',
        'description': 'Check out our new website to learn more about our products, and our development blog for updates on what we\'re up to!',
    },
];

import OmniDial from '../images/omnidialcroppedtaller.png';
import OmniDialVertical from '../images/omnidialvertical.png';
import OmniDialMoreVertical from "../images/omnidialmorevertical.png";

export default function Hero({ image }) {
    return (
        <Flex
            align="center"
            justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
            direction={{ base: 'column' }}
            wrap="no-wrap"
            minH="70vh"
            px={{base: 4, md: 8}}
            mb={16}
        >
            <Box position="relative" mt={-8} w="100vw">
                <Image src={OmniDial} size="100%" shadow="2xl" display={{ base: 'none', md: 'block' }} />
                <Image src={OmniDialMoreVertical} w="100%" shadow="2xl" display={{ base: 'block', md: 'none' }}
                       mx="auto" />

                <Box position="absolute" top={{ base: '70%', md: '50%' }} left={{ base: '50%', md: '75%' }}
                     transform="translate(-50%, -50%)">
                    <Text marginBottom="5px" fontSize={36} fontWeight="regular" fontFamily="oswald">
                        Omni Dial
                    </Text>
                    <Link to="products/omnidial">
                        <Button fontSize={24} border="4px solid #ffffff" borderRadius="2px" height="50px" fontFamily="oswald" color="#ffffff" bg="transparent">
                            Learn More
                        </Button>
                    </Link>
                </Box>
            </Box>


            <SimpleGrid
                spacing={8}
                // templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
                columns={{base: 1, md: 2, lg: 3}}
                justify="center"
                px={{base: 0, md: 4 }}
                // pt={{base: 16 }}
                mt={12}
            >

                {cards.map((card, index) => (
                    <Card key={index} variant="elevated" shadow="2xl"
                        // background="#202020"
                          background="transparent"
                          border="4px solid #ffffff"
                          rounded="1rem"
                          // w={{base: "90%"}}
                        maxW={{base: "600px", lg: "400px"}}
                    >
                        <CardHeader display="flex" alignItems="center" mb="-15px" fontWeight="bold" fontFamily="oswald" color="#ffffff">
                            {/*<Image src={card.icon} w="50px" mr={5}/>*/}
                            <Box as={card.icon} w={5} h={5} mr={2} />
                            {card.title}
                        </CardHeader>
                        <CardBody color="#ffffff">{card.description}</CardBody>
                    </Card>
                ))
                }

            </SimpleGrid>

            <Text pt={8}>© AnomalyWC 2023 - △ ☽ α</Text>


            {/*<Stack*/}
            {/*  spacing={4}*/}
            {/*  w={{ base: "80%", md: "50%" }}*/}
            {/*  align={["center", "center", "flex-start", "flex-start"]}*/}
            {/*>*/}
            {/*  <Heading*/}
            {/*    as="h1"*/}
            {/*    size="xl"*/}
            {/*    fontWeight="bold"*/}
            {/*    // fontSize={48}*/}
            {/*    color="primary.800"*/}
            {/*    textAlign={["center", "center", "left", "left"]}*/}
            {/*    bgGradient="linear(to-r, #7928CA, #FF0080)"*/}
            {/*    // bgGradient="linear(to-l, #0000FF, #FF0000)"*/}
            {/*    bgClip="text"*/}
            {/*  >*/}
            {/*    {title}*/}
            {/*  </Heading>*/}
            {/*  <Heading*/}
            {/*    as="h2"*/}
            {/*    size="md"*/}
            {/*    // color="primary.800"*/}
            {/*    // color="white"*/}
            {/*    // opacity="0.8"*/}
            {/*    fontWeight="normal"*/}
            {/*    // fontSize={24}*/}
            {/*    lineHeight={1.5}*/}
            {/*    textAlign={["center", "center", "left", "left"]}*/}
            {/*  >*/}
            {/*    {subtitle}*/}
            {/*  </Heading>*/}
            {/*  <Link to={ctaLink}>*/}
            {/*    <Button*/}
            {/*      // colorScheme="blackAlpha"*/}
            {/*      borderRadius="8px"*/}
            {/*      py="4"*/}
            {/*      px="4"*/}
            {/*      lineHeight="1"*/}
            {/*      size="md"*/}
            {/*      // fontSize={20}*/}
            {/*    >*/}
            {/*      {ctaText}*/}
            {/*    </Button>*/}
            {/*  </Link>*/}
            {/*  /!*<Text*!/*/}
            {/*  /!*    fontSize="xs"*!/*/}
            {/*  /!*    mt={2}*!/*/}
            {/*  /!*    textAlign="center"*!/*/}
            {/*  /!*    color="primary.800"*!/*/}
            {/*  /!*    opacity="0.6"*!/*/}
            {/*  /!*>*!/*/}
            {/*  /!*    Filler text.*!/*/}
            {/*  /!*</Text>*!/*/}
            {/*</Stack>*/}
            {/*<Box w={{ base: "80%", base: "80%", md: "50%" }} mb={{ base: 12, md: 0 }}>*/}
            {/*  <Image src={image} size="100%" rounded="1rem" shadow="2xl" />*/}
            {/*  /!*<img src={mainImage} alt="Image"/>*!/*/}
            {/*</Box>*/}
        </Flex>
    );
}
