
import React, { useRef, useEffect } from 'react';
import Navbar from './navbar';
import Hero from './herosection';

export default function HomePage() {

    return (
        <>
            <Navbar />
            <Hero />
        </>
    );
}